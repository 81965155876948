<div class="container-grid navbar navbar-expand-lg navbar-dark"><a routerLink="start" class="responsive-picture picture-link-1">
    <picture><img alt="Centre Stage | Online" title="Centre Stage | Online" src="./assets/O.png"></picture>
  </a>
  <h3 class="navbar-brand nav-col"><span class="heading-text-2"><span class="heading-text-2" id="heading-text-1">Centre Stage&nbsp;| Online</span></span></h3>

  <button type="button" class="btn navbar-toggler navbar-toggler-icon button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"></button>
  <div class="container-grid collapse navbar-collapse" id="navbarNavDropdown">
    <ul class="list-container navbar-nav" *ngFor="let itm of mainNavData" >
      <li class="list-item-container nav-item">

        <a class="link-text nav-link nav-col nav-size"  routerLink="{{itm.acf.link}}" routerLinkActive="active" >{{itm.acf.linkname}}</a>
      </li>
    </ul>
    <ul class="list-container navbar-nav">
      <li class="list-item-container nav-item online">
        <h3 class="navbar-brand nav-col"><a routerLink="/reflectivefilm" title="Go to: Centre Stage&nbsp;| Reflective film" class="heading-text-2"><span class="heading-text-2" id="heading-text-1">Go to: Centre Stage&nbsp;| Reflective film</span></a></h3>
      </li>
    </ul>
  </div>
</div>
