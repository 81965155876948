  <div class="container-grid placeholder-hero index">
    <main class="container-grid container-main-text-about main index">
      <h3 class="h3 index"><span class="h2">Welcome to <br>Centre Stage Online!</span></h3>
      <main class="container-grid main-text-about">
        <p class="paragraph main-text_about index">
          Centre Stage Online is a course for performing arts and music artists who wish to develop their communication and artistic leadership skills. Experienced course leaders will guide you through the training and along the way you’ll get to hear several voices from the performing arts industry. <br><br>
          During the course, we’ll take a closer look at power structures and prevailing norms, all from a gender perspective. You will get to reflect on your own development and how you can take control of and communicate your professional self. The goal is that after the course you will have the tools to further develop a professional and successful artistic careeer.<br><br>
          The course is free and available in several different languages. Since it is web-based, you can take the course at your own pace <br><br>
            <span class="paragraph-text-15">What are you waiting for? Choose your language and let’s go</span><br></p>
      </main>
    </main>
    <p class="paragraph hero-tagline size index">
      <span class="paragraph-text-7" id="paragraph-text-2"><span class="paragraph-text-6">Choose language</span></span></p>
    <div class="container-grid drop-bg-color dropdown" id="Choose-language">
      <a class="link-button dripdown-toggle-start btn-secondary-start btn-start" href="#" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true" id="dropdownMenuButton-1-copy-2" data-outofview="true" title="Select your language"></a>
      <div class="container-grid dropdown-menu dropdown-index-mod" aria-labelledby="dropdownMenuButton-1">
        <nav class="container-grid wrap-one-language">
          <div class="responsive-picture picture-2">
            <picture><img alt="German flag" src="./assets/6_languages_GER.png"></picture>
          </div>
          <a class="link-text dropdown-item" tabindex="1" title="Deutsch" (keyup.enter)="selectLanguagehandler('DE')" (click)="selectLanguagehandler('DE')">Deutsch</a>
        </nav>
        <nav class="container-grid wrap-one-language">
          <div class="responsive-picture picture-2">
            <picture><img alt="English flag" src="./assets/6_languages_ENG.png"></picture>
          </div>
          <a class="link-text dropdown-item" tabindex="2" title="English" (keyup.enter)="selectLanguagehandler('EN')" (click)="selectLanguagehandler('EN')">English</a>
        </nav>
        <nav class="container-grid wrap-one-language">
          <div class="responsive-picture picture-2">
            <picture><img alt="Spanish flag" src="./assets/6_languages_SPN.png"></picture>
          </div>
          <a class="link-text dropdown-item" tabindex="3"  title="Español" (keyup.enter)="selectLanguagehandler('SP')" (click)="selectLanguagehandler('SP')">Español</a>
        </nav>
        <nav class="container-grid wrap-one-language">
          <div class="responsive-picture picture-2">
            <picture><img alt="French flag" src="./assets/6_languages_FRA.png"></picture>
          </div>
          <a class="link-text dropdown-item" tabindex="4" title="Français" (keyup.enter)="selectLanguagehandler('FR')" (click)="selectLanguagehandler('FR')">Français</a>
        </nav>
        <nav class="container-grid wrap-one-language">
          <div class="responsive-picture picture-2">
            <picture><img alt="Polish flag" src="./assets/6_languages_POL.png"></picture>
          </div>
          <a class="link-text dropdown-item" tabindex="5" title="Polskie" (keyup.enter)="selectLanguagehandler('PL')" (click)="selectLanguagehandler('PL')">Polskie</a>
        </nav>
        <nav class="container-grid wrap-one-language">
          <div class="responsive-picture picture-2">
            <picture><img alt="Swedish flag" src="./assets/6_languages_SWE.png"></picture>
          </div>
          <a class="link-text dropdown-item" tabindex="6" title="Svenska" (keyup.enter)="selectLanguagehandler('SV')" (click)="selectLanguagehandler('SV')">Svenska</a>
        </nav>
      </div>
    </div>
    
    <div class="container-grid container-1 index">
      <div class="responsive-picture picture-1 logo-upper">
        <picture><img alt="Co-funded by the Createve Europe Programme of the European Union" title="Co-funded by the Createve Europe Programme of the European Union" src="./assets/EU-logga_600.png"></picture>
      </div>
    </div>
  </div>