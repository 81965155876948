<article class="container-grid wrap-main-text gdpr">
  <main class="container-grid container-main-text-about ingress">
    <main class="container-grid main-text-about" >      
      <div class="container-grid div-headings" [innerHTML]="mainPageData[0].acf.mainheader">
        <span class="lds-dual-ring"></span>
      </div>   
      <p class="paragraph main-text_about ingress" [innerHTML]="mainPageData[0].acf.quoteblock">
        <span class="lds-dual-ring"></span>   
      </p>
      
    </main>
  </main>
  <main class="container-grid container-main-text-about main">
    <main class="container-grid main-text-about">
      <p class="paragraph main-text_about" [innerHTML]="mainPageData[0].acf.maincontent">
        <span class="lds-dual-ring"></span>
      </p>
      <div class="rule rule-3">
          <hr />
      </div>
      
      <form [formGroup]="regFormgoup" (ngSubmit)="onSubmit()" class="form-container form-container-1">
        <h5 class="heading-1">{{formlangdata.header.text}}</h5>
        <label class="label label-1 name" for="First name">{{formlangdata.firstname.labeltxt}}*</label>
        <label class="label label-1 surname" for="Surname">{{formlangdata.lastname.labeltxt}}*</label>
        <input 
          [class.is-invalid]="txtfirstname.invalid && txtfirstname.touched" formControlName="txtfirstname" id="txtfirstname"  data-placeholder="{{formlangdata.firstname.placeholdertxt}}"
          name="First name"
          type="text"
          class="input-2"
          title="First name"          
            />
        <input
        [class.is-invalid]="txtlastname.invalid && txtlastname.touched" formControlName="txtlastname" id="txtlastname"  placeholder="{{formlangdata.lastname.placeholdertxt}}"
          name="Surname"
          type="text"
          class="input-1"
          title="Surname"
          required="required"
        />
        <label class="label label-1">{{formlangdata.gender.labeltxt}}*</label>
        <label class="radio radio-button-1">
          <input type="radio" name="radiogender" value="Female" formControlName="radiogender"  required />
          <span>{{formlangdata.gender.alternativ.alt1}}</span>
        </label>
        <label class="radio radio-button-2">
          <input type="radio" name="radiogender" value="Male" formControlName="radiogender"  required />
          <span>{{formlangdata.gender.alternativ.alt2}}</span>
        </label>
        <label class="radio radio-button-3">
          <input type="radio" name="radiogender" value="Other" formControlName="radiogender" required  />
          <span>{{formlangdata.gender.alternativ.alt3}}</span>
        </label>                
        <label class="label label-1 normal" [class.d-none]="radiogender.value!='Other'">{{formlangdata.comments.labeltxt}}</label>
        <textarea  [class.d-none]="radiogender.value!='Other'"
        formControlName="txtcomments" id="txtcomments" placeholder="{{formlangdata.comments.placeholdertxt}}"
          name="gender-comments"
          class="textarea-1"
          title="gender-comments"
        ></textarea>
        <label class="label label-1">{{formlangdata.country.labeltxt}}*</label>
        <input
        [class.is-invalid]="txtcountry.invalid && txtcountry.touched" formControlName="txtcountry" id="txtcountry" placeholder="{{formlangdata.country.placeholdertxt}}"
         name="country"
          type="text"
          class="input-4"
          title="country"
        />
        <label class="label label-1">{{formlangdata.profession.labeltxt}}*</label>
        <input
        [class.is-invalid]="txtprofession.invalid && txtprofession.touched" formControlName="txtprofession" id="txtprofession" placeholder="{{formlangdata.profession.placeholdertxt}}"
          name="profession"
          type="text"
          class="input-3"
          required="required"
          title="profession"
        />
        <label class="label label-1">{{formlangdata.email.labeltxt}}*</label>
        <input
        [class.is-invalid]="txtemail.invalid && txtemail.touched" formControlName="txtemail" id="txtemail"  placeholder="{{formlangdata.email.placeholdertxt}}"
          name="email-name"
          type="email"
          class="email-1"
          required="required"
          title="email-name"
        />
        <label class="label label-1">{{formlangdata.organisation.labeltxt}}</label>
        <input value="" name="email-name" type="text" class="input-3" formControlName="txtorganisation" id="txtorganisation"  placeholder="{{formlangdata.organisation.placeholdertxt}}"/>
        <button type="submit" [disabled]="!regFormgoup.valid" class="link-button btn btn-1002 register" >{{formlangdata.submit.btntext}}</button>              
      </form>
    </main>
  </main>
</article>