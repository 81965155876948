<nav class="container-grid all-episodes" id="all-episodes">
  <a class="link-button btn-lecture btn-1" routerLink="/episodes/episode-1" routerLinkActive="active" title="{{courselangdata.one.linktext}}">    
    {{courselangdata.one.linktext}}
  </a>
  <a class="link-button btn-lecture btn-2" routerLink="/episodes/episode-2" routerLinkActive="active" title="{{courselangdata.two.linktext}}">
    {{courselangdata.two.linktext}}
  </a>
  <a class="link-button btn-lecture btn-3" routerLink="/episodes/episode-3" routerLinkActive="active" title="{{courselangdata.three.linktext}}">
    {{courselangdata.three.linktext}}
  </a>
  <a class="link-button btn-lecture btn-4" routerLink="/episodes/episode-4" routerLinkActive="active" title="{{courselangdata.four.linktext}}">
    {{courselangdata.four.linktext}}
  </a>
  <a class="link-button btn-lecture btn-5" routerLink="/episodes/episode-5" routerLinkActive="active" title="{{courselangdata.five.linktext}}">
    {{courselangdata.five.linktext}}
    <br />
  </a>
  <a class="link-button btn-lecture btn-6" routerLink="/episodes/episode-6" routerLinkActive="active" title="{{courselangdata.six.linktext}}">
    {{courselangdata.six.linktext}}
  </a>
  <a class="link-button btn-lecture btn-7" routerLink="/episodes/episode-7" routerLinkActive="active" title="{{courselangdata.seven.linktext}}">
    {{courselangdata.seven.linktext}}
  </a>
  <a class="link-button btn-lecture btn-8" routerLink="/episodes/episode-8" routerLinkActive="active" title="{{courselangdata.eight.linktext}}">
    {{courselangdata.eight.linktext}}
  </a>
  <a class="link-button btn-lecture btn-9" routerLink="/episodes/episode-9" routerLinkActive="active" title="{{courselangdata.nine.linktext}}">
    {{courselangdata.nine.linktext}}
  </a>
  <a class="link-button btn-lecture btn-10" routerLink="/episodes/episode-10" routerLinkActive="active" title="{{courselangdata.ten.linktext}}">
    {{courselangdata.ten.linktext}}
  </a>
  <a class="link-button btn-lecture btn-11" routerLink="/episodes/episode-11" routerLinkActive="active" title="{{courselangdata.eleven.linktext}}">
    {{courselangdata.eleven.linktext}}
  </a>
  <a class="link-button btn-lecture btn-12" routerLink="/episodes/episode-12" routerLinkActive="active" title="{{courselangdata.twelve.linktext}}">
    {{courselangdata.twelve.linktext}}
  </a>
  <a class="link-button btn-lecture btn-13" routerLink="/episodes/episode-13" routerLinkActive="active" title="{{courselangdata.thirteen.linktext}}">
    {{courselangdata.thirteen.linktext}}
  </a>
  <a class="link-button btn-lecture btn-14" routerLink="/episodes/episode-14" routerLinkActive="active" title="{{courselangdata.fourteen.linktext}}">
    {{courselangdata.fourteen.linktext}}
  </a>
  <a class="link-button btn-lecture btn-fill" href="#"></a>
  <a class="link-button btn-lecture btn-fill" href="#"></a>
</nav>
