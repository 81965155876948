  <article class="container-grid wrap-main-text background-about">
    <main class="container-grid container-main-text-about ingress">
      <main class="container-grid main-text-about" [innerHTML]="mainPageData[0].acf.quoteblock">
        </main>
    </main>
    <main class="container-grid container-main-text-about main">
      <main class="container-grid main-text-about" [innerHTML]="mainPageData[0].acf.maincontent">
      </main>
    </main>
  </article>
  <article class="container-grid wrap-main-text partners" >
    <main class="container-grid container-main-text partners co-funder" [innerHTML]="mainPageData[0].acf.worksheetblock">      
    </main>
    <main class="container-grid container-main-text partners" [innerHTML]="mainPageData[0].acf.extramaterialblock">      
    </main>

    
  </article>  
  <article class="container-grid wrap-main-text background video">
    <div class="container-grid wrap-video-about">
     
      <div class="responsive-video video-platform-1 vimeo" data-original-url="https://vimeo.com/252417024" *ngIf="showVideobox" >
        <iframe [src]="videoUrl" frameborder="0" allow="autoplay; fullscreen" webkitAllowFullScreen mozallowfullscreen allowfullscreen ></iframe>
        </div>
    </div>
    
    <main class="container-grid container-main-text">
      <main class="container-grid main-text" [innerHTML]="mainPageData[0].acf.extrablock2">
      </main>
      <a class="link-button btn btn-1002 about" routerLink="/episodesstart" title="{{btnstart}}"><span class="button-link-text-6" id="button-link-text-1">{{btnstart}}</span></a>
      <div class="rule rule-1">
        <hr>
      </div>
    </main>
  </article>
  