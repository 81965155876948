<article class="container-grid wrap-participants">
  <section class="container-grid outer-wrap-sources" [innerHTML]="mainPageData[0].acf.quoteblock"> 
    <div class="lds-dual-ring"></div>   
</section>
</article>
<article class="container-grid wrap-sources">
  <section class="container-grid outer-wrap-sources" [innerHTML]="mainPageData[0].acf.maincontent">
    <div class="lds-dual-ring"></div>
  </section>
</article>
<app-coursenav></app-coursenav> 