
import {Injectable } from "@angular/core";

@Injectable() // Decorator that marks a class as available to be provided and injected as a dependency.
export class LocalStorageHandler {
    //pagedata:any = [];

    constructor() {  

    }
  
   
    // getLanguage(){      
    //     if (localStorage.getItem("lang")) { 
    //         return true;
    //     }else{
    //         return false;
    //     }
                           
    //    //return localStorage.getItem();      
       
    // }
    // setLanguage(lang){                       
    //     localStorage.setItem("lang",lang);      
    //     return localStorage.getItem("lang");
    //  }
}
