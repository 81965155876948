
   <article class="container-grid wrap-main-text kat overview" *ngIf="welcomeBlock">
    <main class="container-grid container-main-text overview-intro" >
      <div class="container-grid wrap-pic-header" [innerHTML]="mainPageData[0].acf.mainheader">
        
      </div>
      <main class="container-grid main-text-co-funded overview"  [innerHTML]="mainPageData[0].acf.worksheetblock"  >
      </main>
    </main>
    <main class="container-grid container-main-text partners">
      <main class="container-grid main-text-partners">
        <div class="container-grid div-headings"></div>
      </main>
    </main>
  </article>
  <article class="container-grid wrap-main-text overview">
    <main class="container-grid container-main-text-about ingress">
      <main class="container-grid main-text-about" [innerHTML]="mainPageData[0].acf.quoteblock">
        <div class="lds-dual-ring"></div>        
      </main>
    </main>
    <main class="container-grid container-main-text-about main">
      <main class="container-grid main-text-about" >
        <div [innerHTML]="mainPageData[0].acf.maincontent">
          <div class="lds-dual-ring"></div>
        </div>  
        <a class="link-button btn btn-1002 overview" routerLink="/episodes/episode-1" title="{{btnstart}}">
          <span class="button-link-text-6"><span class="button-link-text-6" id="button-link-text-1">{{btnstart}}</span></span>
        </a>
      </main>
    </main>
  </article>
  
    <app-coursenav></app-coursenav>  

  