import { Component, OnInit } from '@angular/core';
import { WpApiService } from './../../services/wp-Api/wp-api.service';
import { Global } from '../models/global';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tillganglighet',
  templateUrl: './tillganglighet.component.html',
  styleUrls: ['./tillganglighet.component.scss']
})
export class TillganglighetComponent implements OnInit {

  mainPageData:any=[];
  videoUrl:any;
  showVideobox:boolean = false;
  dangerousVideoUrl:any;
  constructor(private wpApi:WpApiService, private glb:Global, private titleService: Title) {
  }

  ngOnInit() {
    this.titleService.setTitle( this.glb.HeadTitleMapper("accessibility"));
    this.wpApi.currentPageDataHandler.subscribe(()=>{
        this.getpagedata();
      })
    this.getpagedata();
  }

  selectLanguageChangeHandler (event: any) {
    //update the ui
    let lang = event.target.value;
    this.glb.setUserLanguage(lang);
    this.getpagedata();
  }

  getpagedata(){
    this.wpApi.getPageENSlug("accessibility").subscribe(Response => {
      this.mainPageData = Response
   

      console.log(this.mainPageData)
    });
  }
  
}
