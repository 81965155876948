import { Router } from '@angular/router';

import {Injectable } from "@angular/core";

@Injectable() // Decorator that marks a class as available to be provided and injected as a dependency.
export class redirectHandler {
    //pagedata:any = [];

    // constructor(private router: Router) {  

    // }

    // RedirectNotRegisterd(){
    //     this.router.navigate(['/register']);
    // }
}