  <footer class="container-grid footer-container-wrap footer-no-margin">
    <div class="container-grid dropup">
      <a class="link-button btn btn-secondary dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true" id="dropdownMenuButton-1-copy-1" data-outofview="true" title="Select your language"></a>
      <div class="dropdown-menu container-grid" aria-labelledby="dropdownMenuButton-1">
        <nav class="container-grid wrap-one-language">
          <div class="responsive-picture picture-2">
            <picture><img alt="German flag" src="./assets/6_languages_GER.png"></picture>
          </div>
          <a class="link-text dropdown-item" tabindex="1" title="Deutsch" (keyup.enter)="selectLanguagehandler('DE')" (click)="selectLanguagehandler('DE')">Deutsch</a>
        </nav>
        <nav class="container-grid wrap-one-language">
          <div class="responsive-picture picture-2">
            <picture><img alt="English flag" src="./assets/6_languages_ENG.png"></picture>
          </div>
          <a class="link-text dropdown-item" tabindex="2" title="English" (keyup.enter)="selectLanguagehandler('EN')" (click)="selectLanguagehandler('EN')">English</a>
        </nav>
        <nav class="container-grid wrap-one-language">
          <div class="responsive-picture picture-2">
            <picture><img alt="Spanish flag" src="./assets/6_languages_SPN.png"></picture>
          </div>
          <a class="link-text dropdown-item" tabindex="3"  title="Español" (keyup.enter)="selectLanguagehandler('SP')" (click)="selectLanguagehandler('SP')">Español</a>
        </nav>
        <nav class="container-grid wrap-one-language">
          <div class="responsive-picture picture-2">
            <picture><img alt="French flag" src="./assets/6_languages_FRA.png"></picture>
          </div>
          <a class="link-text dropdown-item" tabindex="4" title="Français" (keyup.enter)="selectLanguagehandler('FR')" (click)="selectLanguagehandler('FR')">Français</a>
        </nav>
        <nav class="container-grid wrap-one-language">
          <div class="responsive-picture picture-2">
            <picture><img alt="Polish flag" src="./assets/6_languages_POL.png"></picture>
          </div>
          <a class="link-text dropdown-item" tabindex="5" title="Polskie" (keyup.enter)="selectLanguagehandler('PL')" (click)="selectLanguagehandler('PL')">Polskie</a>
        </nav>
        <nav class="container-grid wrap-one-language">
          <div class="responsive-picture picture-2">
            <picture><img alt="Swedish flag" src="./assets/6_languages_SWE.png"></picture>
          </div>
          <a class="link-text dropdown-item" tabindex="6" title="Svenska" (keyup.enter)="selectLanguagehandler('SV')" (click)="selectLanguagehandler('SV')">Svenska</a>
        </nav>
      </div>
    </div>
    <p class="paragraph text-footer"><span class="paragraph-text-1"><span class="paragraph-text-1" id="paragraph-text-1">
      <span class="paragraph-text-2">Information and contact | Centre Stage</span><br>
      Kristina Johansson,&nbsp;Project Coordinator<br>
      kristina.ac.johansson@vgregion.se <br>
      
      <br><span class="paragraph-text-3">
        <a title="Accessibility of centrestageonline.org" routerLink="/accessibility" class="paragraph-text-5">Accessibility of centrestageonline.org</a></span><br>
      </span></span>
    </p>
    <div class="responsive-picture picture-1">
      <picture><img alt="Co-funded by the Createve Europe Programme of the European Union" src="./assets/EU-logga_600.png" title="Co-funded by the Createve Europe Programme of the European Union"></picture>
    </div>
    <select [(ngModel)]='selectedval' id="drplang" (change)="selectLanguageChangeHandler($event)" style="display:none;" >
      <option *ngFor="let langitm of langdrp" [value]="langitm.type" >{{langitm.name}}</option>
  </select>
  </footer>

