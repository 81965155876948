<ng-container *ngIf="!showaltmeny">
  <app-mainnav></app-mainnav>
</ng-container>

<router-outlet></router-outlet>

<ng-container *ngIf="!showaltmeny">
  <app-mainfooter></app-mainfooter>
</ng-container>

<cookie-law position="bottom" name="ng4topCookieLaw" expiration="1">
  {{cookieText}}
  <a href="https://github.com/andreasonny83/angular2-cookie-law">
  {{cookieLinktext}}
  </a>
</cookie-law>
